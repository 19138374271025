import React, { useState, useEffect } from "react";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import Modal from "../Modal/Modal";
import "../Slider/Slider.css";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";

const Slider = () => {
  const [current, setCurrent] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Get More Details');
  const [formValues, setFormValues] = useState({ name: '', email: '', mobile: '', query: '' });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const validate = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case 'name':
        errors.name = /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should contain only alphabets.';
        break;
      case 'email':
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address.';
        break;
      case 'mobile':
        errors.mobile = /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits.';
        break;
      case 'query':
        errors.query = value.length >= 15 ? '' : 'Query should be at least 15 characters long.';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    validate(name, value); // Validate the field in real-time
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate all fields before submitting
    Object.keys(formValues).forEach((name) => validate(name, formValues[name]));

    if (Object.values(formErrors).some((error) => error !== '')) {
      setSubmissionError('Please fix the errors in the form.');
      return;
    }

    setSubmitting(true);

    emailjs.send('service_gwx9h8c', 'template_8fchos7', formValues, '5vvqi6coftIzkClRe')
      .then((response) => {
        Swal.fire('Success', 'Email sent successfully!', 'success');
        setSubmitting(false);
        setShowModal(false);
      }, (error) => {
        console.log('FAILED...', error);
        setSubmitting(false);
        setSubmissionError('Failed to send email. Please try again later.');
        Swal.fire('Error', 'Failed to send email. Please try again later.', 'error');
      });
  };

 

  const showModalHandler = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };


  const slides = [
    { src: "a1.webp" },
    { src: "slider.jpg" },
    { src: "aw.jpeg" },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrent((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [slides.length]);





  const handleNext = () => {
    setCurrent((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrent((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="slider">
      <div className="div-card">
        <h2 className="div-card-title">Ace Noida Extension</h2>
        <p className="div-card-location">Sector 12, Noida Extension</p>
        <h3 className="div-card-subtitle">3.5 & 4.5 BHK Luxury Apartments</h3>
        <div className="div-card-pricing">
          <p className="pre-launch-price">Total land : 6.5acars</p>
         
        </div>
        <ul className="div-card-features">
          <li>Club house 50k approx
           </li>
          <li>Tripal height lobby</li>
          <li>4 Lifts in each floor</li>
          
         
        </ul>
        <ul className="div-card-features">
        <div className="div-card-booking">Sizes</div>
          <li>2100-2200sqft (3.5bhk)</li>
          <li>3100-3200sqft(4.5bhk)</li>
          <li>4100-4200sqft(4.5bhk-XL)</li>
          </ul>
       
        <div className="div-card-booking">Book Your EOI</div>
        <div className="div-card-offer">Effective price : 10,999/- ( ONLY for 100 to 150 unit)</div>
        <button className="div-card-enquiry" onClick={() => setShowModal(true)}>Enquire Now</button>
      </div>

      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === current ? "show" : ""}`}
          style={{ display: index === current ? "block" : "none" }}
        >
          <img src={slide.src} alt={`Slide ${index + 1}`} />
        </div>
      ))}

      <a className="prev" onClick={handlePrev}>
        <GrLinkPrevious />
      </a>
      <a className="next" onClick={handleNext}>
        <GrLinkNext />
      </a>

      <Modal show={showModal} handleClose={hideModal}>
        <h2 className="modal-heading">{modalTitle}</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              className={`input ${formErrors.name ? 'invalid' : ''}`}
              required
            />
            {formErrors.name && <span className="error">{formErrors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className={`input ${formErrors.email ? 'invalid' : ''}`}
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              className={`input ${formErrors.mobile ? 'invalid' : ''}`}
              required
            />
            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="query">Your Query:</label>
            <textarea
              id="query"
              name="query"
              value={formValues.query}
              onChange={handleChange}
              className={`input ${formErrors.query ? 'invalid' : ''}`}
              rows="4"
              required
            />
            {formErrors.query && <span className="error">{formErrors.query}</span>}
          </div>
          <div className="butt-f">
            <button className="button-3" type="submit" disabled={submitting}>
              {submitting ? 'Submitting...' : 'SUBMIT'}
            </button>
          </div>
          {submissionError && <p className="submission-error">{submissionError}</p>}
        </form>
      </Modal>
    </div>
  );
};

export default Slider;
