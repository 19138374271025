import React, { useState } from 'react';
import '../Gallery/Gallery.css'; 

const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const images = [
      { id: 'card-g-0', src: null },
      { id: 'card-g-1', src: 'G1.jpg' },
      { id: 'card-g-2', src: 'G2.jpg' },
      { id: 'card-g-3', src: 'G3.jpg' }, 
      { id: 'card-g-4', src: 'G4.jpg' }, 
      { id: 'card-g-5', src: 'G5.jpg' },
      { id: 'card-g-6', src: 'G6.jpg' },
      { id: 'card-g-7', src: 'G7.jpg' },
      { id: 'card-g-8', src: 'G8.webp' },
    ];
  
    const handleImageClick = (img) => {
      setSelectedImage(img);
    };
  
    const handleOverlayClick = () => {
      setSelectedImage(null);
    };
  
    return (
      <section className="gallery">
        <div className="grid-container">
          {images.map((item, index) => (
            <div
              id={item.id} 
              className="card-g"
              key={index}
            >
              {item.src ? (
                <img
                  src={item.src}
                  alt={`Gallery Item ${index}`}
                  onClick={() => handleImageClick(item.src)}
                  className="gallery-image"
                />
              ) : index === 0 ? (
                <div className="text-content">
                  <h3>Our Other properties</h3>
                  <p>Showcasing exceptional properties in every stunning detail</p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
  
        {selectedImage && (
          <div className="overlay3" onClick={handleOverlayClick}>
            <div className="overlay-content3" onClick={(e) => e.stopPropagation()}>
              <img src={selectedImage} alt="Selected" className="overlay-image3" />
              <button className="close-button3" onClick={handleOverlayClick}>
                &times;
              </button>
            </div>
          </div>
        )}
      </section>
    );
  };
  
  export default Gallery;